<template>
  <div class="row-share">
    <div class="n-share" :class="!show?'minh':''">
      <div class="title">Share -</div>
      <div class="wp1200">
        <ul class="ul-share" v-if="list.length > 1">
          <li v-for="(item, index) in list" :key="item.id">
            <div
              class="con"
              data-aos="fade-up"
              :data-aos-delay="index % 4 == 0 ? '0' : '200'"
              @click="gotoDetail(item.id)"
            >
              <div class="box">
                <div class="pic">
                  <img :src="item.image" alt="" />
                </div>
                <div class="txt">
                  <div class="name">
                    {{ item.title }}
                  </div>
                  <span class="more">READ MORE</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div
          class="g-loadmore"
          v-if="!isLoadStatus && nums > list.length"
          @click="loadMore"
        >
          <span class="tit">SEE MORE</span>
        </div>
        <!-- <div class="loadBtnbox" v-if="isLoadStatus">
          <el-button class="loadBtn" type="primary" :loading="true"></el-button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
export default {
  data () {
    return {
      show:false,

      nums: 0,
      page: 1,
      pageSize: 5,
      list: [],

      loadEnd: false,

      isLoadStatus: false, //列表加载中
    }
  },
  methods: {
    getShare (page) {
      let that = this;
      let data = {
        page: that.page
      };
      that.$http
        .getShare(data)
        .then((res) => {
          if (res.ret == 0) {
            // console.log(res);
            let result = res.data
            setTimeout(function () {
              that.show = true
            }, 300);
            AOS.init({
              duration: 1000,
            });
            if (result.list.length == 0) {
              that.list = []
              that.nums = result.nums
              that.loadEnd = true
              return false
            }
            that.isLoadStatus = false
            that.nums = result.nums
            that.list = that.list.concat(result.list);
            that.loadEnd = true
          }
        })
    },
    // 加载更多
    loadMore () {
      let that = this;
      // that.loadTxt = '加载中'
      that.isLoadStatus = true;
      if (that.nums > that.page * that.pageSize ) {
        that.page += 1
        that.getShare(that.page);
      }

    },
    gotoDetail (id) {
      let that = this

      localStorage.setItem('yhgshareDetailsId', id);
      that.$router.push({
        path: '/sharedetails/'+id,
        /* name: 'shareDetails',
        params: {
          // detailsData: that.detailsData
          id: id
        } */
      })


    },
  },
  mounted () {
    this.getShare();
  }
}
</script>

<style>
</style>